// export default () => '这是巡查日历';

import { useCallback, useState } from 'react';

import {
  getDataSourceList,
  getDataTableList,
  getNewDataTableList,
  getDataFieldList,
  getNewDataFieldList,
  getDataFieldBySqlList,
  getDataTagTree,
  getDataFieldTypeSelectList,
  getAllApplicationList,
  getAllServiceList,
  getStandardDictionaryList,
  getGovernanceStandardTree,
  getGovernanceCrossReferenceTableTree,
  getBusinessTypeList,
  getCleanTaskList,
} from '../services/DataCenter';
import { getTreeNode, changeTreeNew } from '@/utils/utils';
import { formatTreeData, treeLeafSelect } from '@/utils/treeUtils';

import type { DefaultOptionType } from 'antd/lib/select';
import type { DataNode } from 'antd/es/tree';

export default function useDataCenterM() {
  // 数据核查规则 树
  const checkRuleTree = [
    {
      title: '核查规则',
      key: '0',
      value: '0',
      // selectable: false,
      children: [
        {
          title: '唯一性校验',
          key: 'UNIQUENESS_CHECK',
          value: 'UNIQUENESS_CHECK',
        },
        {
          title: '完整性校验',
          key: 'INTEGRITY_CHECK',
          value: 'INTEGRITY_CHECK',
        },
        {
          title: '准确性校验',
          key: 'ACCURACY_VERIFICATION',
          value: 'ACCURACY_VERIFICATION',
          children: [
            {
              title: '准确性校验-长度准确性',
              key: 'ACCURACY_VERIFICATION_LENGTH',
              value: 'ACCURACY_VERIFICATION_LENGTH',
            },
            {
              title: '准确性校验-正则表达式',
              key: 'ACCURACY_VERIFICATION_REGULAR',
              value: 'ACCURACY_VERIFICATION_REGULAR',
            },
          ],
        },
        {
          title: '一致性校验',
          key: 'CONSISTENCY_CHECK',
          value: 'CONSISTENCY_CHECK',
        },
        {
          title: '关联性校验',
          key: 'RELEVANCE_CHECK',
          value: 'RELEVANCE_CHECK',
        },
        {
          title: '及时性校验',
          key: 'TIMELINESS_VERIFICATION',
          value: 'TIMELINESS_VERIFICATION',
        },
      ],
    },
  ];
  // 数据核查规则 l列表
  const checkRuleList: DefaultOptionType[] = [
    {
      title: '唯一性校验',
      label: '唯一性校验',
      key: 'UNIQUENESS_CHECK',
      value: 'UNIQUENESS_CHECK',
    },
    {
      title: '完整性校验',
      label: '完整性校验',
      key: 'INTEGRITY_CHECK',
      value: 'INTEGRITY_CHECK',
    },
    {
      title: '准确性校验',
      label: '准确性校验',
      key: 'ACCURACY_VERIFICATION',
      value: 'ACCURACY_VERIFICATION',
    },
    {
      title: '准确性校验-长度准确性',
      label: '准确性校验-长度准确性',
      key: 'ACCURACY_VERIFICATION_LENGTH',
      value: 'ACCURACY_VERIFICATION_LENGTH',
    },
    {
      title: '准确性校验-正则表达式',
      label: '准确性校验-正则表达式',
      key: 'ACCURACY_VERIFICATION_REGULAR',
      value: 'ACCURACY_VERIFICATION_REGULAR',
    },
    {
      title: '一致性校验',
      label: '一致性校验',
      key: 'CONSISTENCY_CHECK',
      value: 'CONSISTENCY_CHECK',
    },
    {
      title: '关联性校验',
      label: '关联性校验',
      key: 'RELEVANCE_CHECK',
      value: 'RELEVANCE_CHECK',
    },
    {
      title: '及时性校验',
      label: '及时性校验',
      key: 'TIMELINESS_VERIFICATION',
      value: 'TIMELINESS_VERIFICATION',
    },
  ];

  const levelEnum: DefaultOptionType[] = [
    { id: 'HIGH', value: 'HIGH', label: '高' },
    { id: 'MEDIUM', value: 'MEDIUM', label: '中' },
    { id: 'LOW', value: 'LOW', label: '低' },
  ];

  // 数据库
  const databaseEnum: DefaultOptionType[] = [
    { id: '1', value: 'MYSQL', label: 'MYSQL' },
    { id: '2', value: 'SQLSERVER', label: 'SQLSERVER' },
    { id: '3', value: 'ORACLE', label: 'ORACLE' },
  ];

  // 路由策略
  const routingPolicyEnum: DefaultOptionType[] = [
    { id: '1', value: 'RANDOM', label: '随机' },
    { id: '2', value: 'FIRST', label: '第一个' },
  ];

  // 数据类型
  const dataTypeEnum: DefaultOptionType[] = [
    { id: '1', value: 'String', label: '字符串' },
    { id: '2', value: 'Integer', label: '整数' },
    { id: '3', value: 'Float', label: '浮点' },
    { id: '4', value: 'Boolean', label: '布尔' },
    { id: '5', value: 'List', label: '列表' },
  ];
  // 主题类型
  const themeEnum: DefaultOptionType[] = [
    { id: '1', value: 'default', label: '经典' },
    { id: '2', value: 'dark', label: '暗黑' },
  ];
  const requestMethodEnums: DefaultOptionType[] = [
    { id: '1', value: 'GET', label: 'Get' },
    { id: '2', value: 'POST_FORM_DATA', label: 'Post(application/form-data)' },
    { id: '3', value: 'POST_JSON', label: 'Post(application/json)' },
  ];
  const statusEnums: DefaultOptionType[] = [
    { id: '1', value: 'DOWN_LINE', label: '已下线' },
    { id: '2', value: 'PUBLISHED', label: '已发布' },
    { id: '3', value: 'TO_BE_PUBLISHED', label: '待发布' },
  ];
  const runEnums: DefaultOptionType[] = [
    { id: '1', value: 'true', label: '运行' },
    { id: '2', value: 'false', label: '暂停' },
  ];
  const standardCategoryEnums: DefaultOptionType[] = [
    { id: 'NATIONAL', value: 'NATIONAL', label: '国家标准' },
    { id: 'INDUSTRY', value: 'INDUSTRY', label: '行业标准' },
    { id: 'LOCAL', value: 'LOCAL', label: '地方标准' },
    { id: 'ENTERPRISE', value: 'ENTERPRISE', label: '企业标准' },
  ];
  const standardControlFieldEnums: DefaultOptionType[] = [
    { id: 'CODE', value: 'CODE', label: '代码' },
    { id: 'NAME', value: 'NAME', label: '名称' },
  ];
  // 标志位类型
  const flagBitTypeEnums: DefaultOptionType[] = [
    { id: 'ID_INCREASING', value: 'ID_INCREASING', label: 'ID自增' },
    { id: 'TIME_INCREASING ', value: 'TIME_INCREASING ', label: '时间自增' },
  ];

  const [dataSourceSelectList, setDataSourceSelectList] = useState<DefaultOptionType[]>([]); // 数据源列表
  const [dataTableSelectList, setDataTableSelectList] = useState<DefaultOptionType[]>([]); // 数据表格列表
  const [newDataTableSelectList, setNewDataTableSelectList] = useState<DefaultOptionType[]>([]); // 数据表格列表
  const [dataFieldList, setDataFieldList] = useState<API.CheckboxItem[]>([]); // 数据字段列表
  const [newDataFieldList, setNewDataFieldList] = useState<API.CheckboxItem[]>([]); // 数据字段列表
  const [dataTagTree, setDataTagTree] = useState<API.MyDataNode[]>([]); // 数据标签树
  const [dataTagTreeNode, setDataTagTreeNode] = useState<string[]>([]); // 数据标签节点
  const [dataFieldTypeSelectList, setDataFieldTypeSelectList] = useState<DefaultOptionType[]>([]); // 数据中台-字段类型
  const [dataApplicationList, setDataApplicationList] = useState<DefaultOptionType[]>([]); // 数据中台-所有应用
  const [dataServiceList, setDataServiceList] = useState<DefaultOptionType[]>([]); // 数据中台-所有服务

  const [standardDictionaryList, setStandardDictionaryList] = useState<DefaultOptionType[]>([]); // 数据标准字典列表
  const [businessTypeList, setBusinessTypeList] = useState<DefaultOptionType[]>([]); // 业务类型列表
  const [cleanTaskList, setCleanTaskList] = useState<DefaultOptionType[]>([]); // 清洗任务列表

  const [governanceStandardTreeData, setGovernanceStandardTreeData] = useState<DataNode[]>([]); // 数据标准类型 树
  const [governanceCrossReferenceTableTreeData, setGovernanceCrossReferenceTableTreeData] =
    useState<DataNode[]>([]); // 对照表 树

  // 获取数据标准类型 树
  const getGovernanceStandardTreeData = useCallback(async (params: any) => {
    const res = await getGovernanceStandardTree(params);
    if (res.data) {
      setGovernanceStandardTreeData(
        // treeLeafSelect(changeTreeNew([res.data], 'name', 'id', 'childList') || []),
        treeLeafSelect(
          formatTreeData({
            arrayData: Array.isArray(res.data) ? res.data : [res.data],
            key: 'id',
            labelKey: 'name',
            childNodeKey: 'childList',
          }),
        ),
      );
      // setMenuTreeNode(getTreeNode(res.data, 'id', 'routes'));
    }
  }, []);

  // 获取对照字典 树
  const getGovernanceCrossReferenceTableTreeData = useCallback(async (params: any) => {
    const res = await getGovernanceCrossReferenceTableTree(params);
    if (res.data) {
      setGovernanceCrossReferenceTableTreeData(
        treeLeafSelect(
          formatTreeData({
            arrayData: Array.isArray(res.data) ? res.data : [res.data],
            key: 'id',
            labelKey: 'name',
            childNodeKey: 'childList',
          }),
        ),
      );
    }
  }, []);

  // 获取数据源列表
  const getDataSourceSelectListData = useCallback(async () => {
    const res = await getDataSourceList();
    if (res.data) {
      setDataSourceSelectList(
        res.data.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        id: item.id,
        label: item.name,
        value: item.id,
      }));
    }
  }, []);

  // 获取数据表格列表
  const getDataTableSelectListData = useCallback(async (params: any) => {
    const res = await getDataTableList(params);
    if (res.data) {
      setDataTableSelectList(
        res.data.map((item: string) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: string) => ({
        id: item,
        label: item,
        value: item,
      }));
    } else {
      return [];
    }
  }, []);

  // 获取新数据表格列表
  const getNewDataTableSelectListData = useCallback(async (params: any) => {
    const res = await getNewDataTableList(params);
    if (res.data) {
      setNewDataTableSelectList(
        res.data.map((item: string) => ({
          id: item,
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: string) => ({
        id: item,
        label: item,
        value: item,
      }));
    } else {
      return [];
    }
  }, []);

  // 获取数据字段列表
  const getDataFieldListData = useCallback(async (params: any) => {
    const res = await getDataFieldList(params);
    if (res.data) {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res.data.map((item: any) => ({
        label: item,
        value: item,
      }));
    }
  }, []);

  // 获取 --新-- 数据字段列表
  const getNewDataFieldListData = useCallback(async (params: any) => {
    const res = await getNewDataFieldList(params);
    if (res.data) {
      setNewDataFieldList(
        res.data.map((item: any) => ({
          ...item,
          label: item.fieldName,
          value: item.id,
        })),
      );
      return res.data.map((item: any) => ({
        ...item,
        label: item.fieldName,
        value: item.id,
      }));
    }
  }, []);

  // 获取数据源列表
  const getDataFieldBySqlListData = useCallback(async (params: any) => {
    const res = await getDataFieldBySqlList(params);
    if (res.code === '0') {
      setDataFieldList(
        res.data.map((item: any) => ({
          label: item,
          value: item,
        })),
      );
      return res;
    } else {
      return res;
    }
  }, []);

  // 获取数据标签数
  const getDataTagTreeData = useCallback(async () => {
    const res = await getDataTagTree();
    if (res.data) {
      // res.data 不是数组，改一下结构
      setDataTagTree(changeTreeNew(res.data.childList, 'name', 'code', 'childList') || []);
      setDataTagTreeNode(getTreeNode(res.data.childList, 'code', 'childList'));
    }
  }, []);

  // 获取数据中台-字段类型列表
  const getDataFieldTypeSelectListData = useCallback(async () => {
    const res = await getDataFieldTypeSelectList();
    if (res.data) {
      setDataFieldTypeSelectList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有应用
  const getDataApplicationListData = useCallback(async (params: any) => {
    const res = await getAllApplicationList(params);
    if (res.data) {
      setDataApplicationList(
        res.data.map((item: any) => ({
          id: item.mysqlType,
          label: item.mysqlType,
          value: item.mysqlType,
          ...item,
        })),
      );
    }
  }, []);

  // 获取数据中台-所有服务
  const getDataServiceListData = useCallback(async (params: any) => {
    const res = await getAllServiceList(params);
    if (res.data) {
      setDataServiceList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 数据标准字典 列表
  const getStandardDictionaryListData = useCallback(async (params: any) => {
    const res = await getStandardDictionaryList(params);
    if (res.data) {
      setStandardDictionaryList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  // 业务类型 列表
  const getBusinessTypeListData = useCallback(async (params: any) => {
    const res = await getBusinessTypeList(params);
    if (res.data) {
      setBusinessTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
          code: item.code,
        })),
      );
    }
  }, []);

  // 清洗任务 列表
  const getCleanTaskListData = useCallback(async (params: any) => {
    const res = await getCleanTaskList(params);
    if (res.data) {
      setCleanTaskList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, []);

  return {
    checkRuleTree,
    checkRuleList,
    levelEnum,
    databaseEnum,
    routingPolicyEnum,
    dataTypeEnum,
    themeEnum,
    requestMethodEnums,
    statusEnums,
    runEnums,
    standardCategoryEnums,
    standardControlFieldEnums,
    flagBitTypeEnums, // 标志位类型
    // 数据标准类别 树
    governanceStandardTreeData,
    getGovernanceStandardTreeData,
    // 对照表 树
    governanceCrossReferenceTableTreeData,
    getGovernanceCrossReferenceTableTreeData,
    // 数据源下拉
    dataSourceSelectList,
    getDataSourceSelectListData,
    // 数据表格下拉
    dataTableSelectList,
    setDataTableSelectList,
    getDataTableSelectListData,
    // 新--数据表格下拉
    newDataTableSelectList,
    getNewDataTableSelectListData,
    // 数据字段下拉
    dataFieldList,
    setDataFieldList,
    getDataFieldListData,
    // 新--数据字段下拉
    newDataFieldList,
    getNewDataFieldListData,
    // sql 查询数据字段
    getDataFieldBySqlListData,
    // 数据标签树
    dataTagTree,
    dataTagTreeNode,
    getDataTagTreeData,
    // 获取数据中台-字段类型列表
    dataFieldTypeSelectList,
    getDataFieldTypeSelectListData,
    // 获取数据中台-应用列表
    dataApplicationList,
    getDataApplicationListData,
    // 获取数据中台-服务列表
    dataServiceList,
    getDataServiceListData,
    // 获取数据治理平台-数据标准字典列表
    standardDictionaryList,
    getStandardDictionaryListData,
    // 业务类型 列表
    businessTypeList,
    getBusinessTypeListData,
    // 清洗任务 列表
    cleanTaskList,
    getCleanTaskListData,
  };
}
