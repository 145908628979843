import http from '@/utils/request';

/**
 *  ---数据中台---
 *  */
/** 获取数据源列表 */
export async function getDataSourceList() {
  return http.post({ url: '/hohai/data/exchange/data/source/itemList' });
}
/**
 * @description 获取数据表列表
 * @param datasourceId  id
 *  */
export async function getDataTableList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getTables', params });
}

/** 获取--新--数据表列表 */
export async function getNewDataTableList(params: any) {
  return http.get({ url: '/hohai/data/platform/metadata/getTableNames', params });
}

/** 获取数据字段列表 */
export async function getDataFieldList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getColumns', params });
}

/** 获取--新--数据字段列表 */
export async function getNewDataFieldList(params: any) {
  return http.post({ url: '/hohai/data/platform/metadata/list', params });
}

/** 获取数据字段列表--sql */
export async function getDataFieldBySqlList(params: any) {
  return http.get({ url: '/hohai/data/exchange/metadata/getColumnsByQuerySql', params });
}

/** 获取数据标签数 */
export async function getDataTagTree() {
  return http.post({ url: '/hohai/data/platform/asset/label/getTree' });
}

/** 获取-字段类型列表 */
export async function getDataFieldTypeSelectList() {
  return http.get({ url: '/hohai/data/platform/asset/field/getTypeList' });
}

/** 获取所有应用 */
export async function getAllApplicationList(params: any) {
  return http.post({
    url: `/hohai/data/platform/app/info/getList`,
    params,
  });
}

/** 获取所有服务 */
export async function getAllServiceList(params: any) {
  return http.post({
    url: `/hohai/data/platform/api/info/getList`,
    params,
  });
}

/** 获取图表列表 */
export async function getChartList(params: any) {
  return http.post({
    url: `/hohai/data/platform/chart/config/list`,
    params,
  });
}

/**
 * @description 获取数据标准类型 树
 * @param {string} category 等级 国家级、行业级、地区级、企业级
 *
 *  */
export async function getGovernanceStandardTree(params: any) {
  return http.post({
    url: `/hohai/data/exchange/governance/standard/category/getTree`,
    params,
  });
}
/**
 * @description 获取数据标准字典 列表
 * @param
 *
 *  */
export async function getStandardDictionaryList(params: any) {
  return http.post({
    url: `/hohai/data/exchange/governance/standard/dictionary/itemList`,
    params,
  });
}
/**
 * @description 获取对照表 树
 * @param {string} databaseSourceId 数据库id
 * @param {string} tableName 表名
 * @param {string} standardCategoryId 标准类型id
 *
 *  */
export async function getGovernanceCrossReferenceTableTree(params: any) {
  return http.post({
    url: `/hohai/data/exchange/governance/crossReferenceTable/getTree`,
    params,
  });
}

/**
 * @description 获取业务类型 列表
 * @param
 *
 *  */
export async function getBusinessTypeList(params: any) {
  return http.post({
    url: `/hohai/data/exchange/governance/businessType/itemList`,
    params,
  });
}
/**
 * @description 获取清洗任务 列表
 * @param
 *
 *  */
export async function getCleanTaskList(params: any) {
  return http.post({
    url: `/hohai/data/exchange/governance/cleanTask/itemList`,
    params,
  });
}
