import type { DataNode } from 'antd/es/tree';

/**
 *
 * @description: 格式化树数据
 * @param {Array} arrayData 数据
 * @param {string} key key值
 * @param {string} labelKey 展示值
 * @param {string} childNodeKey 子节点key
 * @returns DataNode[]
 */
export const formatTreeData: ({
  arrayData,
  key,
  labelKey,
  childNodeKey,
}: {
  arrayData: any[];
  key: string;
  labelKey: string;
  childNodeKey: string;
}) => DataNode[] = ({ arrayData, key, labelKey, childNodeKey }) => {
  return arrayData.map((item) => {
    return {
      ...item,
      key: item[key],
      value: item[key],
      title: item[labelKey],
      children: item[childNodeKey]
        ? formatTreeData({
            arrayData: item[childNodeKey],
            key: key,
            labelKey: labelKey,
            childNodeKey: childNodeKey,
          })
        : [],
    };
  });
};

/**
 *
 * @description: 仅叶子节点可选
 * @param {DataNode} treeData 数据
 * @returns DataNode[]
 */
export const treeLeafSelect: (treeData: DataNode[]) => DataNode[] = (treeData) => {
  if (treeData.length === 0) {
    return [];
  }
  return treeData.map((item: any) => {
    if (item.children && item.children.length !== 0) {
      return {
        ...item,
        selectable: false,
        children: treeLeafSelect(item.children),
      };
    } else {
      return {
        ...item,
        selectable: true,
      };
    }
  });
};

/**
 *
 *  @description: 处理增加树根节点
 * @param {DataNode[]} treeData 数据
 * @param {React.ReactNode} rootName 根节点展示值
 * @param {string} rootId 根节点key值
 * @param {boolean} selectable 是否可选
 * @returns
 */
export const addTreeRoot: ({
  treeData,
  rootName,
  rootId,
  selectable,
}: {
  treeData: DataNode[];
  rootName: React.ReactNode;
  rootId: string;
  selectable: boolean;
}) => DataNode[] = ({ treeData, rootName, rootId, selectable }) => {
  if (!!treeData) {
    return [
      {
        key: rootId,
        title: rootName,
        children: treeData,
        selectable: selectable,
      },
    ];
  } else {
    return [{ key: rootId, title: rootName, selectable: selectable }];
  }
};

// /**
//  *
//  *  @description: 处理树
//  * @param {API.MyDataNode[]} arr 数据
//  * @param {string} title 展示值
//  * @param {string} value key值
//  * @param {string} childNode 子节点key
//  * @returns
//  */
// export const changeTree: (
//   arr: DataNode[],
//   title: string,
//   value: string,
//   childNode: string,
// ) => DataNode[] = (arr, title, value, childNode) => {
//   if (arr.length === 0) {
//     return addTreeRoot({
//       treeData: [],
//       rootName: '暂无数据',
//       rootId: '-1',
//       selectable: false,
//     }) as DataNode[];
//   }
//   return arr.map((item) => {
//     if (
//       item[childNode as keyof DataNode] &&
//       (item[childNode as keyof DataNode] as DataNode[]).length !== 0
//     ) {
//       return {
//         ...item,
//         key: item.key,
//         title: item[title as keyof DataNode],
//         label: item[title as keyof DataNode],
//         value: item[value as keyof DataNode],
//         children: changeTree(
//           item[childNode as keyof DataNode] as DataNode[],
//           title,
//           value,
//           childNode,
//         ),
//       };
//     } else {
//       if (item[childNode as keyof DataNode]) {
//         delete item[childNode as keyof DataNode];
//       }
//       return {
//         ...item,
//         key: item.key,
//         title: item[title as keyof DataNode],
//         label: item[title as keyof DataNode],
//         value: item[value as keyof DataNode],
//       };
//     }
//   }) as DataNode[];
// };

// /**
//  *
//  *  @description: 处理树
//  * @param {API.MyDataNode} arr 数据
//  * @param {string} key id对应字段
//  * @param {string} title 展示值
//  * @param {string} value key值
//  * @param {string} childNode 子节点
//  * @returns
//  */
// export const changeTreeNew = (
//   arr: API.MyDataNode[],
//   title: string,
//   value: string,
//   childNode: string,
// ): API.MyDataNode[] => {
//   if (arr.length === 0) {
//     return [];
//   }
//   return arr.map((item) => {
//     // if (item[childNode as keyof API.MyDataNode] && item[childNode as keyof API.MyDataNode]?.length !== 0) {
//     if (item[childNode as keyof API.MyDataNode]) {
//       return {
//         ...item,
//         title: item[title as keyof API.MyDataNode],
//         value: item[value as keyof API.MyDataNode] || item.id,
//         children: changeTree(
//           item[childNode as keyof API.MyDataNode] as API.MyDataNode[],
//           title,
//           value,
//           childNode,
//         ),
//       };
//     } else {
//       if (item[childNode as keyof API.MyDataNode]) {
//         delete item[childNode as keyof API.MyDataNode];
//       }
//       return {
//         ...item,
//         title: item[title as keyof API.MyDataNode],
//         value: item[value as keyof API.MyDataNode] || item.id,
//       };
//     }
//   }) as API.MyDataNode[];
// };

// //处理树
// export const changeMenuTree: (arr: API.routeItem[]) => API.routeItem[] = (arr) => {
//   if (arr.length === 0) {
//     return [];
//   }
//   return arr
//     .map((item) => {
//       delete item.layout;
//       if (item.routes && item.routes.length !== 0) {
//         return {
//           ...item,
//           key: item.id,
//           name: item.menuName,
//           access: 'canView',
//           routes: changeMenuTree(item.routes),
//         };
//       } else {
//         if (item.routes) {
//           delete item.routes;
//         }
//         return {
//           ...item,
//           key: item.id,
//           name: item.menuName,
//           target: item.isLink ? '_blank' : '_self',
//           access: 'canView',
//         };
//       }
//     })
//     .filter((item) => item);
// };

// //处理树
// export const changeMenuList: (arr: API.menuListItem[]) => any = (arr = []) => {
//   if (arr.length === 0) {
//     return;
//   }
//   return arr.map((item) => {
//     if (item.routes && item.routes.length !== 0) {
//       return [item.path, ...changeMenuList(item.routes)];
//     } else {
//       if (item.routes) {
//         delete item.routes;
//       }
//       return item.path;
//     }
//   });
// };

/**
 *
 *  @description: 获取全部树节点
 * @param {DataNode[]} treeData 数据
 * @returns
 */
export const getTreeNodeKeys: (treeData: DataNode[]) => string[] = (treeData) => {
  if (treeData.length === 0) {
    return [];
  }
  const keyArray: string[] = [];
  const getTreeKeyArray: (currentTreeData: DataNode[]) => void = (currentTreeData) => {
    currentTreeData.map((item) => {
      if (item.children && item.children.length !== 0) {
        keyArray.push(item.key as string);
        getTreeKeyArray(item.children);
      } else {
        keyArray.push(item.key as string);
      }
    });
  };
  getTreeKeyArray(treeData);
  return keyArray;
};
