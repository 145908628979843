/**
 * 网络请求工具 封装umi-request
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */

import { extend } from 'umi-request';
import type { RequestOptionsInit } from 'umi-request';
import { history } from 'umi';
import { notification } from 'antd';
import { getToken } from '@/utils/utils';

// codeMessage仅供参考 具体根据和后端协商,在详细定义.
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  403: '登录已过期，请重新登录。',
  500: '服务器发生错误，请检查服务器。',
  503: '服务器发生错误，请检查服务器。',
};
type mapCode = 200 | 400 | 500;

/**
 * 错误异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status as mapCode] || response.statusText;
    // const { status, url } = response;
    if (response.status === 403) {
      history.push('/user/login');
    }
    notification.error({
      // message: `请求错误 ${status}: ${url}`,
      // message: `请求错误 ${status}`,
      message: ``,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// 根据不同的开发环境,配置请求前缀
// interface ApiPrefix {
//   dev: string;
//   test?: string;
//   prd?: string;
// }
// const apiPreFix: ApiPrefix = {
//   dev: 'http://120.55.193.14:3030/',
//   //   test: 'http://120.55.193.14:3030/',
//   //   prd: 'http://120.55.193.14:3030/',
// };
// request拦截器, 携带token,以及根据环境,配置不同的请求前缀
request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
  // 不携带token的请求数组
  const notCarryTokenArr: string[] = ['/hohai/auth/login/loginByUserName'];
  if (notCarryTokenArr.includes(url) || url.includes('anonymous')) {
    return {
      //   url: `${apiPreFix.dev}${url}`,
      url,
      options,
    };
  }
  // 给每个请求带上token
  //   const token = localStorage.getItem('tokens') || '';
  const token = getToken() || '';
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };
  return {
    // url: `${apiPreFix.dev}${url}`,
    url,
    options: { ...options, interceptors: true, headers },
  };
});

/**
 * @url 请求的url
 * @parameter 上传的参数
 */

// 封装的get,post.put,delete请求
const get = async ({
  url,
  params,
}: {
  url: string;
  params?: Record<string, unknown>;
}): Promise<any> => {
  try {
    const res = await request(url, { method: 'get', params: params });
    // if (res.code === 1) {
    //   notification.error({
    //     message: `请求错误`,
    //   });
    // }
    return res;
  } catch (error) {
    console.error(error);
  }
};
/**
 * @url 请求的url
 * @parameter 上传的参数
 */

// 封装的get,post.put,delete请求
const getBlob = async ({
  url,
  params,
}: {
  url: string;
  params?: Record<string, unknown>;
}): Promise<any> => {
  try {
    const res = await request(url, {
      method: 'get',
      getResponse: true,
      params: params,
      responseType: 'blob',
    });
    // if (res.code === 1) {
    //   notification.error({
    //     message: `请求错误`,
    //   });
    // }
    return {
      blobData: res.data,
      fileName: decodeURI(res.response.headers.get('content-disposition')?.split('=')[1] || ''),
    };
  } catch (error) {
    console.error(error);
  }
};

const post = async ({
  url,
  data,
  params,
  options,
}: {
  url: string;
  data?: Record<string, unknown>;
  params?: Record<string, string>;
  options?: any;
}): Promise<any> => {
  const formData = new FormData();
  if (params) {
    Object.keys(params).map((item) => {
      formData.append(item, params[item]);
    });
  }
  try {
    const res = await request(url, {
      method: 'post',
      options: options,
      data: params ? formData : data,
      // params: params,
    });
    if (res.code === 1) {
      notification.error({
        message: `请求错误`,
      });
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};

const postBlob = async ({
  url,
  data,
  params,
  options,
}: {
  url: string;
  data?: Record<string, unknown>;
  params?: Record<string, string>;
  options?: any;
}): Promise<any> => {
  const formData = new FormData();
  if (params) {
    Object.keys(params).map((item) => {
      formData.append(item, params[item]);
    });
  }
  try {
    const res = await request(url, {
      getResponse: true,
      method: 'post',
      options: options,
      data: params ? formData : data,
      // params: formData,
      responseType: 'blob',
    });
    return {
      blobData: res.data,
      fileName: decodeURI(res.response.headers.get('content-disposition')?.split('=')[1] || ''),
    };
  } catch (error) {
    console.error(error);
  }
};

const postImportBlob = async ({
  url,
  data,
  params,
  options,
}: {
  url: string;
  data?: Record<string, unknown>;
  params?: Record<string, string>;
  options?: any;
}): Promise<any> => {
  try {
    const res = await request(url, {
      method: 'post',
      options: options,
      data: data,
      params: params,
    });
    if (res.code === 1) {
      notification.error({
        message: `请求错误`,
      });
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};

// const deletes = async (url: string, parameter?: Record<string, unknown>): Promise<any> => {
//   try {
//     const res = await request(url, { method: 'delete', params: parameter });
//     return res;
//   } catch (error) {
//     console.error(error);
//   }
// };

// const put = async (url: string, parameter?: Record<string, unknown>): Promise<any> => {
//   try {
//     const res = await request(url, { method: 'put', data: parameter });
//     return res;
//   } catch (error) {
//     console.error(error);
//   }
// };

export default {
  get,
  getBlob,
  post,
  postBlob,
  postImportBlob,
  // put,
  // deletes,
};
